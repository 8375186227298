import styled from "@emotion/styled";

export const CreateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 296px;
  height: 74px;
  font-family: "Rubik";
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.035em;
  text-align: right;
  border-radius: 12px;
  color: #ffffff;
  background: #2e6cf6;
  cursor: pointer;
`;

export const MainList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 29px;
`;

export const MainItem = styled.li`
  display: flex;
  gap: 12px;
`;

export const LeftWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 10px 12px;
  border-radius: 12px;
  border: 2px solid #2e6cf6;
  background: #ecf5ff;
  width: 100%;
`;

export const BlockList = styled.ul`
  display: flex;
  gap: 8px;
  max-width: 332px;
  width: 100%;
`;

export const BlockItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 162px;
  min-width: 77px;
  width: 100%;
  height: 57px;
  padding: 6px 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(26, 31, 51, 0.8);
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.5);
`;

export const OrderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 20px;
  border-radius: 8px;
  border: 2px solid #2e6cf6;
  background: #fff;
`;

export const MainTitle = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.7px;
`;

export const OrderNumber = styled.p`
  color: #000;
  font-family: "Rubik";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.98px;
`;

export const EditButton = styled.button`
  width: 220px;
  height: 77px;
  border-radius: 12px;
  background: #2e6cf6;
  border: transparent;
  color: #fff;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
  cursor: pointer;
`;

export const CopyButton = styled.button`
  width: 220px;
  height: 77px;
  border-radius: 12px;
  background: #f1c40f;
  border: transparent;
  color: #fff;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  width: 77px;
  height: 74px;
  border: transparent;
  border-radius: 12px;
  background: #f62e6a;
  cursor: pointer;
`;

export const TopBlockText = styled.p`
  font-family: "Rubik";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.84px;
`;

export const BottomBlockText = styled.p`
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.49px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);

  .is-hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: ${(props) => props.height};
  padding: 16px;
  border-radius: 14px;
  background-color: #ffffff;
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const ConfirmButton = styled.button`
  width: 175px;
  height: 55px;
  border: transparent;
  border-radius: 12px;
  background: ${(props) => props.background};
  cursor: pointer;
  color: #fff;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
`;

export const CancelButton = styled.button`
  width: 175px;
  height: 55px;
  border: transparent;
  border-radius: 12px;
  background: ${(props) => props.background};
  cursor: pointer;
  color: #fff;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const ModalText = styled.p`
  color: #000;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
`;

export const TopButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
