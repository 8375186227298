import { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Background, Container } from "./App.styled";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";
import { Login } from "./components/Login/Login";
import { AddItem } from "./components/AddItem/AddItem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HeaderTable } from "./components/HeaderTable/HeaderTable";
import { Defaults } from "./components/Defaults/Defaults";
import logo from "./assets/logo.png";

function App() {
  const PASSWORDCRM = process.env.REACT_APP_KEY;

  const [password, setPassword] = useState(
    localStorage.getItem("loginInSlotoUa") ?? ""
  );
  const [creating, setCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  if (PASSWORDCRM === password) {
    localStorage.setItem("loginInSlotoUa", password);
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          <></>
          // <Background>
          //   <Container>
          //     <Header />
          //     {PASSWORDCRM === password ? (
          //       <>
          //         {creating ? (
          //           <AddItem />
          //         ) : (
          //           <Main
          //             setCreating={setCreating}
          //             setIsEditing={setIsEditing}
          //             isEditing={isEditing}
          //           />
          //         )}
          //       </>
          //     ) : (
          //       <Login setPassword={setPassword} password={password} />
          //     )}
          //   </Container>
          //   <ToastContainer />
          // </Background>
        }
      />
      {/* <Route
        path="/setdefault"
        element={
          <Container>
            <Link to="/">
              <img
                src={logo}
                alt=""
                width={155}
                style={{ cursor: "pointer", marginBottom: "40px" }}
              />
            </Link>
            <Defaults />
          </Container>
        }
      /> */}
      {/* <Route
        path="/logs"
        element={
          <>
            <HeaderTable />
            <main></main>
            <footer></footer>
          </>
        }
      >
        <Route to="/leads" />
        <Route />
        <Route />
      </Route> */}
    </Routes>
  );
}

export default App;
