import styled from "@emotion/styled";

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  margin-top: 60px;
`;

export const LoginText = styled.p`
  font-family: "Rubik";
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.035em;
  text-align: left;
`;

export const LoginInput = styled.input`
  width: 393px;
  height: 70px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px #0000001a inset;
  border: 3px solid #e4e4e4;
  padding-left: 24px;
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.035em;
  text-align: left;
`;
