import styled from "@emotion/styled";

export const DefaultItem = styled.div`
  width: 165px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.035em;
  text-align: right;
  border-radius: 12px;
  color: #ffffff;
  background: #f1c40f;
  cursor: pointer;
`;

export const DefaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
