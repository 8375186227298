import styled from "@emotion/styled";
import button from "../../assets/button.png";

export const StepsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StepText = styled.p`
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
`;

export const BlockList = styled.ul`
  display: flex;
  gap: 8px;
  max-width: 393px;
  width: 100%;
`;

export const BlocksItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 162px;
  min-width: 77px;
  width: 100%;
  height: 57px;
  padding: 6px 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(26, 31, 51, 0.8);
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.5);
`;

export const TopBlockText = styled.p`
  font-family: "Rubik";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.84px;
`;

export const BottomBlockText = styled.p`
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.49px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 390px;
  height: 283px;
  padding: 8px 20px;
  border-radius: 10px;
`;

export const ItemTitle = styled.p`
  font-family: "Rubik";
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
`;

export const PrizeItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
`;

export const PrizeTopText = styled.p`
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
`;

export const PrizeBottomText = styled.p`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #fdf351;
`;

export const PrizeList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
`;

export const Button = styled.button`
  max-width: 300px;
  width: 100%;
  height: 53px;
  border-radius: 12px;
  border: transparent;
  cursor: pointer;
  background-image: url(${button});
  background-size: 300px 53px;
`;

export const WrapperModal = styled.div`
  width: 387px;
  min-height: 346px;
  padding: 16px;
  border-radius: 14px;
  background-color: #fcfdff;
`;

export const Border = styled.div`
  border: 4px solid #2e6cf666;
  border-radius: 14px;
`;

export const ModalTitle = styled.p`
  color: #333;
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.26px;
`;

export const TopModalWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const UpperModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 29px;
  color: #333;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.63px;
`;

export const ModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 29px;
`;

export const ModalItem = styled.li`
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
`;

export const ModalFakeButton = styled.button`
  max-width: 355px;
  width: 100%;
  height: 74px;
  border-radius: 12px;
  border: 2px solid rgba(174, 196, 246, 0.5);
  background: linear-gradient(93deg, #1450d4 0.02%, #2e6cf6 100.02%);
  color: #fff;
  font-family: "Rubik";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
`;

export const ModalCode = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-family: "Rubik";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.98px;
`;

export const ModalCodeIcon = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

export const ModalCopyIcon = styled.svg`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
`;

export const ModalImage = styled.img`
  max-width: 354px;
  width: 100%;
  height: 74.7px;
`;
