import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export const Header = styled.header`
  width: 100%;
  height: 60px;
  background-color: #000000;
  border-bottom: 2px solid #4c4b4c;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1240px;
  padding: 0 20px;
`;

export const Link = styled(NavLink)`
  &.active {
    color: green;
  }
`;
