import styled from "@emotion/styled";
import background from "./assets/background.png";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 75px;
  margin: 0 auto;
`;

export const Background = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const FooterBackground = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333333;
`;
