import axios from "axios";
axios.defaults.baseURL = "https://naomc.top/api/";
// axios.defaults.baseURL = "http://localhost:5000/api/";

export const sendItem = async (data) => {
  try {
    await axios.post("item/new", data);
  } catch (error) {
    console.log(error);
  }
};

export const getItems = async () => {
  try {
    const { data } = await axios.get("item/all");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getItem = async (_id) => {
  try {
    const { data } = await axios.get(`item/${_id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteItem = async (_id) => {
  try {
    await axios.delete(`item/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const copyItem = async (_id) => {
  try {
    await axios.get(`item/copy/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const updateItem = async (_id, data) => {
  try {
    await axios.patch("item/edit", { _id, data });
  } catch (error) {
    console.log(error);
  }
};

export const sendImage = async (image) => {
  try {
    const { data } = await axios.post("image/send", { image });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrder = async (order) => {
  try {
    const { data } = await axios.get(`item/offer/${order}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sendOffer = async (offer) => {
  try {
    await axios.patch("item/addOffer", { offer });
  } catch (error) {
    console.log(error);
  }
};

export const getOffers = async (_id) => {
  try {
    const { data } = await axios.get(`offer/all?_id=${_id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllOffers = async () => {
  try {
    const { data } = await axios.get("offer/allOffers");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createOffer = async (_id, offer, pid, sub1, website) => {
  try {
    await axios.post("offer/new", { _id, offer, pid, sub1, website });
  } catch (error) {
    console.log(error);
  }
};

export const deleteOffers = async () => {
  try {
    await axios.delete("offer/delete");
  } catch (error) {
    console.log(error);
  }
};

export const updateOffer = async (_id, offer, pid, sub1, website) => {
  try {
    await axios.patch("offer/edit", {
      _id,
      offer,
      pid,
      sub1,
      website,
    });
  } catch (error) {
    console.log(error);
  }
};
