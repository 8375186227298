import styled from "@emotion/styled";
import button from "../../assets/button.png";

export const MainWrapper = styled.div`
  display: flex;
  gap: 65px;
  margin-bottom: 30px;
`;

export const SidesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Input = styled.input`
  width: 380px;
  height: 50px;
  padding-left: 24px;
  border-radius: 12px;
  border: 3px solid #e4e4e4;
  background: #fcfdff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) inset;
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
  cursor: pointer;
`;

export const FileWrapper = styled.div`
  width: 380px;
  height: 50px;
  position: relative;
  cursor: pointer;
`;

export const InputFile = styled.input`
  position: relative;
  z-index: 50;
  opacity: 0;
  width: 380px;
  height: 50px;
  cursor: pointer;
`;

export const FileButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 380px;
  height: 50px;
  border-radius: 12px;
  border: transparent;
  background: #83a6f6;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const InputPromocode = styled.input`
  width: 380px;
  height: 50px;
  padding-left: 24px;
  border-radius: 12px;
  border: 3px solid #e4e4e4;
  background: ${(props) => {
    return props.background ? "#fcfdff" : "#cacaca";
  }};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) inset;
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
  cursor: ${(props) => {
    return props.background ? "pointer" : "not-allowed";
  }};
`;

export const LeftText = styled.p`
  color: #000;
  font-family: "Rubik";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
  text-transform: uppercase;
`;

export const Label = styled.label`
  display: flex;
  gap: 10px;
  color: #333;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const EditButton = styled.button`
  width: 380px;
  height: 57px;
  border-radius: 12px;
  background: #2e6cf6;
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  border: transparent;
  letter-spacing: -0.77px;
`;

export const SaveButton = styled.button`
  width: 380px;
  height: 57px;
  border-radius: 12px;
  background: #61c051;
  color: #fff;
  text-align: center;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
  border: transparent;
  cursor: pointer;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 999;
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 490px;
  height: 389px;
  padding: 16px;
  border-radius: 14px;
  background-color: #ffffff;
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalInput = styled.input`
  width: 458px;
  height: 50px;
  padding-left: 24px;
  border-radius: 12px;
  border: 3px solid #e4e4e4;
  background: ${(props) => {
    return props.disabled ? "#cacaca" : "#fcfdff";
  }};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) inset;
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
  cursor: ${(props) => {
    return props.disabled ? "not-allowed" : "pointer";
  }};
`;

export const ModalButton = styled.button`
  width: 458px;
  height: 57px;
  border-radius: 12px;
  background: #2e6cf6;
  border: transparent;
  color: #fff;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
`;

export const BlocksModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 490px;
  height: 713px;
  padding: 16px;
  border-radius: 14px;
  background-color: #ffffff;
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const BlocksModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BlockNumber = styled.div`
  color: #333;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.77px;
  text-transform: uppercase;
`;

export const BlockLabel = styled.label`
  display: flex;
  gap: 10px;
  color: #333;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.77px;
  cursor: ${(props) => {
    return props.disabled ? "not-allowed" : "pointer";
  }};
`;

export const BlockTopWrapper = styled.div`
  display: flex;
  gap: 26px;
`;

export const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalCheckbox = styled.input`
  cursor: ${(props) => {
    return props.disabled ? "not-allowed" : "pointer";
  }};
`;

export const Item = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 390px;
  height: 283px;
  padding: 8px 20px;
  border-radius: 10px;
`;

export const ItemTitle = styled.p`
  font-family: "Rubik";
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
`;

export const PrizeItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
`;

export const PrizeTopText = styled.p`
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
`;

export const PrizeBottomText = styled.p`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #fdf351;
`;

export const PrizeList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
`;

export const Button = styled.button`
  max-width: 300px;
  width: 100%;
  height: 53px;
  border-radius: 12px;
  border: transparent;
  cursor: pointer;
  background-image: url(${button});
  background-size: 300px 53px;
`;

export const WrapperModal = styled.div`
  width: 387px;
  min-height: 346px;
  padding: 16px;
  border-radius: 14px;
  background-color: #fcfdff;
`;

export const Border = styled.div`
  border: 4px solid #2e6cf666;
  border-radius: 14px;
`;

export const ModalTitle = styled.p`
  color: #333;
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.26px;
`;

export const TopModalWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const UpperModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 29px;
  color: #333;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.63px;
`;

export const ModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 29px;
`;

export const ModalItem = styled.li`
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
`;

export const ModalFakeButton = styled.button`
  max-width: 355px;
  width: 100%;
  height: 74px;
  border-radius: 12px;
  border: 2px solid rgba(174, 196, 246, 0.5);
  background: linear-gradient(93deg, #1450d4 0.02%, #2e6cf6 100.02%);
  color: #fff;
  font-family: "Rubik";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
`;

export const ModalCode = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-family: "Rubik";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.98px;
`;

export const ModalCodeIcon = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

export const ModalCopyIcon = styled.svg`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
`;

export const ModalImage = styled.img`
  max-width: 354px;
  width: 100%;
  height: 74.7px;
`;
